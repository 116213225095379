import React from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';

const YupSchema=Yup.object().shape({
    name:Yup.string()
        .min(2,'name too short')
        .max(50,'Please use shorter name'),
    email:Yup.string()
        .email('Invalid email type'),
    message:Yup.string()
        .min(5,'please provide us with a longer message')
    })
class ContactForm extends React.Component {
    constructor(props){
        super(props)
        this.state={
            communicate:''
            }
    }
    componentDidUpdate(prevState){
        if(this.state.communicate!==prevState.communicate){

            }
        }
    render() {
        return(
            <div className="contact_form">
                <Formik
                    initialValues={{ name: '' ,email:'',message:''}}
                    validationSchema={YupSchema}
                    onSubmit={(values, actions) => {
                        this.setState({communicate:"sending..."})
                        console.log('our values',values)
                        var bagaz=values
                        bagaz.sender='moi'
                        console.log(bagaz)       
                        var request=new Request('https://excellencewebapp.azurewebsites.net/api/mailsender', {
                            method:'POST',
                            headers: new Headers({'Accept': 'application/json',
                              'Content-Type':'application/json'}),
                            body:JSON.stringify(bagaz)
                            })
                          //we make a request
                          fetch(request)
                            //we get response, in form of json
                            .then(response=>response.json())
                            .then(response=>{
                                if(response.answer==="tadaa"){
                                    this.setState({communicate:"message has been sent"})
                                    //we show messagae has been sent 
                                    }
                                    else{
                                        //we show error while sending message
                                        this.setState({communicate:"message has not been delivered,please try again"})
                                    }
                                })
                        }}
                    >
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <div className='contact_inputs_cont'>
                            <input className="contact_inputs" type="text" onChange={props.handleChange} onBlur={props.handleBlur} placeholder={this.props.langObj.ContactForm[0]}
                                value={props.values.name} name="name"/>
                        </div>
                        <div className='contact_inputs_cont'>
                            <input className="contact_inputs" type="email" onChange={props.handleChange} onBlur={props.handleBlur} placeholder={this.props.langObj.ContactForm[1]}
                                value={props.values.email} name="email"/>
                        </div>
                        <div className='contact_inputs_cont'>
                            <textarea className="contact_inputs_message" rows="5" cols="50" onChange={props.handleChange} onBlur={props.handleBlur} placeholder={this.props.langObj.ContactForm[2]}
                                value={props.values.message} name="message"/>
                        </div>
                        {props.errors.email && <div id="feedback">{props.errors.email}</div>}
                        {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                        {props.errors.message && <div id="feedback">{props.errors.message}</div>}
                        <div className="contact_button_cont">
                            <button className="contact_button" type="submit">Send {'>'}</button>
                        </div>
                        {this.state.communicate}
                    </form>
                    )}
                </Formik>
            </div>
            )
        }
    }

const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(ContactForm);