//HERE CONDITIONAL rendering if login is done-check what is going on with variables when you are logging in
import React from 'react';
import {connect} from 'react-redux';
//assign el_id to a variable
import * as Constant from '../options/languages'
import ReactGA from "react-ga";


//class which shows top menu (sign up and log in)
class TopMenu extends React.Component {
    //here is new stuff
    constructor(props){
        super(props)
        //initial state -not logged
        this.state={
            language:null,
            new_lang:null
            }
        this.changeLang=this.changeLang.bind(this)
        }
    //function which changes language
    changeLang(lang){
        ReactGA.event({
            category: 'language',
            action: 'click',
            label: lang,
            value: 1
            })
        console.log('lang',lang)
        this.props.dispatch({type:"language",payload:lang})
        let new_lang=null;
        switch (lang) {
            case "english":{
                new_lang=Constant.englishObj
                break;
                }
            case "french":{
                new_lang=Constant.frenchObj
                break;
                }
            case "polish":{
                new_lang=Constant.polishObj
                break;
                }
            default:{
                new_lang=Constant.englishObj
                }
            }   
        this.props.dispatch({type:"langObj",payload:new_lang})
        }

    //here we start writing what we will see
    render() {
            //we show this
            return(
                <div className="top_menu_options">
                    <div className="top_menu_options_items" onClick={() =>this.changeLang("english")}>
                        English
                    </div>
                    <div className="top_menu_options_items" onClick={() =>this.changeLang("french")}>
                        Français
                    </div>
                    <div className="top_menu_options_items" onClick={() =>this.changeLang("polish")}>
                        Polski
                    </div>
                </div>
                )
            }
    }

const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(TopMenu);