//dependencies
import React from 'react';
import {connect} from 'react-redux';
//components
import icon_mobile from '../../pics/options/icon_mobile.png'
import icon_desktop from '../../pics/options/icon_screen.jpg'
import icon_wrench from '../../pics/options/icon_wrench.png'

class OptionIcons extends React.Component {
    constructor(props){
        super(props)
        this.redirect=this.redirect.bind(this)
        }
    redirect(direction){
        if(direction==='solutions'){
            console.log('here we are')
            window.scrollTo(0,this.props.refCreatingSolutions.current.offsetTop)
            }
        if(direction==='support'){
            window.scrollTo(0,this.props.refProvidingSupport.current.offsetTop)
            }
        if(direction==='working'){
            window.scrollTo(0,this.props.refWorkingOnSite.current.offsetTop)
            }
        }
    render() {
        return(
            <div className='pics_flex'>
                <div className='pic_cont'>
                    <div>
                        <img className='pic_itself' src={icon_desktop} alt='' onClick={()=>this.redirect("solutions")}/>
                    </div>
                    <div className='pics_description'>
                        {this.props.langObj.OptionIcons[0]}
                    </div>
                </div>
                <div className='pic_cont'>
                    <div>
                        <img className='pic_itself' src={icon_mobile} alt='' onClick={()=>this.redirect("support")}/> 
                    </div>
                    <div className='pics_description'>
                    {this.props.langObj.OptionIcons[1]}
                    </div>
                </div>
                <div className='pic_cont'>
                    <div>
                        <img className='pic_itself' src={icon_wrench} alt='' onClick={()=>this.redirect("working")}/> 
                    </div>
                    <div className='pics_description'>
                        {this.props.langObj.OptionIcons[2]}
                    </div>
                </div>
            </div>
            )
        }
    }
const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(OptionIcons);