import React from 'react';
import {connect} from 'react-redux';
//components
import small_screen from '../pics/options_small_icons/small_screen.png'
import truck from '../pics/options_small_icons/truck.png'

class WorkingOnSite extends React.Component {
    render() {
        return(
            <div className="option_section_frame">
                <div className="option_title">
                    {this.props.langObj.WorkingOnSite[0]}
                </div>
                <div className="options_flexbox">
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={small_screen} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.WorkingOnSite[1]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.WorkingOnSite[2]}
                        </div>
                    </div>
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={truck} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.WorkingOnSite[3]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.WorkingOnSite[4]}
                        </div>
                    </div>
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">

                        </div>
                        <div>

                        </div>
                        <div className="option_item_flexbox_text">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(WorkingOnSite);