import React from 'react';

export default class WorkingOnSite extends React.Component {
    render() {
        return(
            <div className="Footer_container">
                <div className="footer_title">
                    MENTIONS LEGALES
                </div>
                <div className="footer_txt">
                    Dirigeant : Mateusz ZDYB
                </div>
                <div className="footer_txt">
                    Tél. 06 38 59 60 97
                </div>
                <div className="footer_txt">
                    Adresse : 19 Rue du Binet, 64400 Agnos
                </div>
                <div className="footer_txt">
                    FRANCE
                </div>
                <div className="footer_txt">
                    SIRET : 851 057 661 00015
                </div>
                <div className="footer_txt">
                    v.1.02
                </div>
            </div>
            )
        }
    }