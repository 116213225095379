export const english=["SAVE TIME, INCREASE PRODUCTIVITY AND IMPROVE CONSISTENCY WITH OUR BESPOKE SERVICES"]
export const englishObj={motto:"SAVE TIME, INCREASE PRODUCTIVITY AND IMPROVE CONSISTENCY WITH OUR BESPOKE SERVICES",
    AboutUs:["About Us",
        "Established in the South-West of France in 2019, Excell-En-Ce specialises in Process Automation and in building Single Page Web Applications.",
        "We propose automatisation of your routine tasks so that you have more time for focusing on more creative part of your work. With our assistance your business performance will improve.",
        "We also offer building a tailored Web Apps, either as your rective Website or as a cloud-based tailored tool to do a specific tasks you require.",
        "We work with any type of activity and any size of company.",
        "Also, we offer everlasting 10% discount for environmentally friendly projects and additional 10% for our recurring customers."
        ],
    OptionIcons:["CREATING SOLUTIONS",
        "PROVIDING SUPPORT",
        "WORKING ON-SITE OR REMOTELY"
        ],
    ContactForm:["Name",
        "Email Address",
        "Message"
        ],
    CreatingSolutions:["CREATING SOLUTIONS",
        "IDENTIFY AREAS FOR IMPROVEMENT",
        "We propose to identify the processes which can be automated either in your office, your production line or your logistics department. We offer free on-site visit or discuss possibilities for improvements online.",
        "DESIGN AND IMPLEMENTATION",
        "We design customizable, ready to use tools which we help to integrate within your existing IT systems.",
        "PROVIDE TRAINING AND IT SUPPORT",
        "We provide you with the necessary training so that you use easily your new automation tools alongside with a maintenance and IT support."
        ],
    ProvidingSupport:["PROVIDING SUPPORT",
        "FOCUS ON CUSTOMER",
        "When contacting us, you will be given an excellent, comprehensive and rapid response, adapted to your needs. This is essential in emergency situations ",
        "TROUBLESHOOTING",
        "We are happy to provide you with a full documentation of our products, FAQ section as well as a dedicated team to help you fully utilise our tools."
        ],
    WorkingOnSite:["WORKING ON-SITE OR REMOTELY",
        "WORKING REMOTELY",
        "We can help you whatever the geographical distance is between us. Through the telephone or Skype we can easily communicate with one another and identify the specific solution you need. A step-by-step documentation will then be sent to you so as to make the implementation process stress-free. Also, you can choose a mix of on-site and remote cooperation.",
        "WORKING ON-SITE",
        "If you prefer us to pay you a visit, we will be happy to send our engineer to work at your site."
        ]
        }
export const polishObj={motto:"OSZCZĘDŹ CZAS, ZWIĘKSZ PRODUKTYWNOŚĆ ORAZ POPRAW JEDNOSTAJNOŚĆ WYNIKÓW PRZY POMOCY NASZYCH ROZWIAZAŃ",
    AboutUs:["O Nas",
        "Excell-en-ce zostało założone w 2019 we Francji. Nasza specjalność to automatyzacja procesów biurowych oraz tworzenie aplikacji internetowych.",
        "Oferujemy usługi w zakresie automatyzacji rutynowych zadań po to, żeby wasz zespół mógł poświęcić więcej czasu na bardziej kreatywną część pracy.",
        "Tworzymy również aplikacje internetowe na zamówienie, ściśle dostosowane do waszych potrzeb.",
        "Współpracujemy z firmami z każdej branży i każdej wielkości.",
        "Proponujemy również 10% zniżki dla eko projektów, jai i dodatkowo rabat 10% dla stałych klientów."
        ],
    OptionIcons:["TWORZENIE ROZWIĄZAŃ",
        "WSPARCIE WE WDRAŻANIU PRODUKTÓW",
        "PRACA U KLIENTA LUB NA ODLEGŁOŚĆ"
        ],
    ContactForm:["Imie",
        "Adres emailu",
        "Wiadomość"
        ],
    CreatingSolutions:[
        "Rozwiązania",
        "IDENTYFIKACJA OBSZARÓW AUTOMATYZACJI",
        "Nasza firma proponuje pomoc w indentyfikacji procesów, które mogą być zautomatyzowane w działach takich jak biuro, departament logistyki lub linia produkcyjna. Oferujemy bezpłatną wizytę lub konsultacje online.",
        "DESIGN I IMPLEMENTACJA",
        "Specjalizujemy się w projetowaniu narzędzi dostosowanych do indiwidualnych potrzeb klienta, które pomagamy zintegrować z już istniejącymi systememi informatycznymi.",
        "ZAPEWNIAMY TRENING I WSPARCIE IT",
        "Oczywiście zapewniamy także trening oraz dokumentację żeby pomóc państwu w całkowitym wykorzystaniu naszych rozwiązań. Proponujemy także serwis i wsparcie IT."
        ],
    ProvidingSupport:["WSPARCIE",
        "ZORIENTOWANIE NA KLIENTA",
        "Wierzymy, że każdy, bez względu na wielkość lub miejsce operowania musi mieć zapewniony błyskawiczny serwis najwyższej klasy, szczególnie w sytuacjach awaryjnych. Taką troskę mogą zapewnić tylko firmy z poziomą strukturą organizacyjną, Nasza firma została zaprojektowana aby móc zapewnić dokładnie taki poziom usług.",
        "ROZWIĄZYWANIE PROBLEMÓW",
        "Do naszych produktów dołączamy pełną dokumentację w formie cyfrowej. W sekcji często zadawanych pytań (FAQ) znajdą państwo szereg wskazówek, które pomogą państwu cieszyć sie naszymi rozwiązaniami. Oferujemy także pomoc dedykowanego zespółu ekspertów."
        ],
    WorkingOnSite:["PRACA ZDALNA LUB U KLIENTA",
        "PRACA NA DYSTANS",
        `Niepokoją sie państwo, ze nasza firma jest w zbyt dużej odległości abyśmy mogli wam pomóc? Proszę się nie obawiać, Excel-en-ce oferuje usługi również na dystans, przy pomocy konsultacji telefoniczych lub przez Skajpa. Oferujemy taże wybów metod dostarczenia naszych rozwiązań: przesyłką polecona lub poprzez unikalny link do ściągnięcia plików przez internet. Do dokumentacji przy pracy na dystans dołączamy także instrukcję "krok po kroku" aby maksymalnie ułatwić implementację naszych rozwiązań. Mogą również państwo wybrać kombinację pracy u klienta z pracą na dystans.`,
        "PRACA U KLIENTA",
        "Jeśli Państwo preferują abyśmy wysłali specjalistę do pracy w waszym odziale, prosze dać nam znać."
        ]
        }
export const frenchObj={motto:"GAGNEZ DU TEMPS, AUGMENTEZ VOTRE PRODUCTIVITE ET ASSUREZ L'UNIFORMITE DE CELLE-CI GRACE A NOTRE SERVICE SUR MESURE",
    AboutUs:["Services",
        "Notre entreprise vous propose une large gamme de services en matière d'automatisation de tâches routinières réalisées sous MS Office et vous offre de nouveaux outils de Machine Learning pour améliorer significativement les performances de votre activité.",
        ],
    OptionIcons:["SOLUTIONS",
        "SUPPORT",
        "INTERVENTION SUR SITE CLIENT OU TRAVAILLER A DISTANCE"
        ],
    ContactForm:["Nom",
        "Email addresse",
        "Message"
        ],
    CreatingSolutions:["SOLUTIONS",
        "IDENTIFIER VOS BESOINS EN VUE D'AMELIORER VOTRE ACTIVITE",
        "Rencontre avec vous afin d'identifier clairement la points d'amélioration possibles de vos process existants (automatisation) puis mise en oeuvre sur votre site de production ou bien en nos locaux. Déplacement jusqu'à vous, entretien, et réalisation d'un devis conforme à vos attentes offerts.",
        "CONCEPTION ET REALISATION",
        "Après étude, nous vous apportons des solutions sur-mesure, adaptées à vos besoins propres, outils prêts à l'emploi, que nous intégrons à votre système informatique existant.",
        "FORMATION ET SUPPORT INFORMATIQUE",
        "Nous vous formons sur les nouveaux outils d'exploitation robotisée à votre disposition et assurons la maintenance de ceux-ci. A tout moment vous pouvez nous solliciter pour un soutien éventuel, une problématique rencontrée, ou une amélioration supplémentaire à apporter."
        ],
    ProvidingSupport:["SUPPORT",
        "DISPONIBILITE ET ECOUTE",
        "Nous apportons à chacun de nos clients une réponse adaptée, pertinente et rapide, particulièrement en cas d'urgence. Toute demande est étudiée avec attention, nous avons le souci de vous satisfaire.",
        "RESOLUTION DE PROBLEMES",
        "Lors de toute intervention, une documentation complète des nouveaux outils installés dans le système informatique existant est fournie, comprenant une partie question et réponses utile en cas de difficulté rencontrée. Parallèlement nous nous tenons à votre disposition pour vous éclairer."
        ],
    WorkingOnSite:["CHEZ EXCEL-EN-CE OU SUR VOTRE SITE",
        "INTERVENTION CHEZ EXCEL-EN-CE",
        "Nous pouvons intervenir à distance et réaliser le travail depuis nos bureaux. Dès lors nous communiquerons par téléphone ou via Skype. Les solutions vous seront envoyées incluant une documentation de mise en service de vos nouveaux outils, pas à pas, facilement compréhensible. Egalement vous pouvez faire le choix de combiner installation en toute autonomie et assitance par l'un de nos techniciens.",
        "INTERVENTION SUR VOTRE SITE",
        "A votre demande nous nous déplaçons sur votre site afin d'intervenir directement dans votre système informatique. N'hésitez pas à nous consulter au préalable pour un devis sur-mesure."
        ]
        }