import React from 'react';
import {connect} from 'react-redux';

import people from '../pics/options_small_icons/people.png'
import wrench from '../pics/options_small_icons/wrench.png'

class ProvidingSupport extends React.Component {
    render() {
        return(
            <div className="option_section_frame">
                <div className="option_title">
                    {this.props.langObj.ProvidingSupport[0]}
                </div>
                <div className="options_flexbox">
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={people} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.ProvidingSupport[1]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.ProvidingSupport[2]}
                        </div>
                    </div>
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={wrench} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.ProvidingSupport[3]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.ProvidingSupport[4]}
                        </div>
                    </div>
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            
                        </div>
                        <div>
                           
                        </div>
                        <div className="option_item_flexbox_text">
                           
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(ProvidingSupport);