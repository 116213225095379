import React from 'react';
import {connect} from 'react-redux';

class AboutUs extends React.Component {
    render() {
        return(
            <div className='about_us'>
                <div className='about_header'>
                    {this.props.langObj.AboutUs[0]}
                </div>
                <div className='about_par'>
                    {this.props.langObj.AboutUs[1]}
                </div>
                <div className='about_par'>
                    {this.props.langObj.AboutUs[2]}
                </div>
                <div className='about_par'>
                    {this.props.langObj.AboutUs[3]}
                </div>
                <div className='about_par'>
                    {this.props.langObj.AboutUs[4]}
                </div>
                <div className='about_par'>
                    {this.props.langObj.AboutUs[5]}
                </div>
            </div>
            )
        }
    }
const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(AboutUs);