//dependencies
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
//importing styles
import '../styles/index.css'
//routes
import ourroutes from './ourroutes'
//components
import TopMenu from './top_menu/Top_Menu'
import AboutUs from './aboutus/AboutUs'
import OptionIcons from './options/OptionIcons'
import ContactForm from './options/ContactForm'
import CreatingSolutions from './CreatingSolutions'
import ProvidingSupport from './ProvidingSupport'
import WorkingOnSite from './WorkingonSite'
import Footer from './Footer'
import WelcomePicture from './WelcomePicture'



class LayoutRouter extends React.Component {
    constructor(props){
        super(props)
        this.refCreatingSolutions=React.createRef()
        this.refProvidingSupport=React.createRef()
        this.refWorkingOnSite=React.createRef()
    }
    render() {
        return(
        <Router routes={ourroutes}>
            <div className="main">
                <div>
                    <TopMenu/>
                </div>
                    <WelcomePicture/>
                <div>
                    <AboutUs/>
                </div>
                <div>
                    <OptionIcons refCreatingSolutions={this.refCreatingSolutions} refProvidingSupport={this.refProvidingSupport} refWorkingOnSite={this.refWorkingOnSite}/>
                </div>
                <div>
                    <ContactForm/>
                </div>
                <div ref={this.refCreatingSolutions}>
                    <CreatingSolutions/>
                </div>
                <div ref={this.refProvidingSupport}>
                    <ProvidingSupport/>
                </div>
                <div ref={this.refWorkingOnSite}>
                    <WorkingOnSite/>
                </div>
                <div>
                    <Footer/>
                </div>

            </div>
        </Router> 
        );
    }    
};

const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(LayoutRouter);