import React from 'react';
import {connect} from 'react-redux';
//images
import main_pic from '../../src/pics/main_excellence_picture.png'
import logo_tranparent from'../pics/Excellence_logo_no_background.png'

class WelcomePicture extends React.Component {
    render() {
        return(
        <div className='main_pic'>
            <img className='main_pic_itself' src={main_pic} alt=''/>
            <div className='imageAndLogo'>
                <img className='logo' src={logo_tranparent||null} alt=''/>
                <div className='motto'>
                    {this.props.langObj.motto}
                </div>
            </div>
        </div>     
        )   
    }
}



const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(WelcomePicture);