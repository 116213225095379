import React from 'react';
import {connect} from 'react-redux';
//images
import lightbulb from '../pics/options_small_icons/lightbulb.png'
import pencil from '../pics/options_small_icons/pencil.png'
import cog from '../pics/options_small_icons/cog.png'


class CreatingSolutions extends React.Component {
    render() {
        return(
            <div className="option_section_frame">
                <div className="option_title">
                    {this.props.langObj.CreatingSolutions[0]}
                </div>
                <div className="options_flexbox">
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={lightbulb} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.CreatingSolutions[1]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.CreatingSolutions[2]}
                        </div>
                    </div>
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={pencil} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.CreatingSolutions[3]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.CreatingSolutions[4]}
                        </div>
                    </div>
                    <div className="options_item_flexbox">
                        <div className="options_item_pic">
                            <img src={cog} alt=''></img>
                        </div>
                        <div>
                            {this.props.langObj.CreatingSolutions[5]}
                        </div>
                        <div className="option_item_flexbox_text">
                            {this.props.langObj.CreatingSolutions[6]}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    language:state.language,
    langObj:state.langObj
    })
export default connect(mapStateToProps)(CreatingSolutions);