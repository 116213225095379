import React from 'react';
import {Switch
    } from 'react-router-dom';




export default  (
    <Switch>
    </Switch>
)
    