
import {englishObj} from '../components/options/languages'
const intialstatelr={
    language:'english',
    langObj:englishObj
  }
  const mainreducer=function(state=intialstatelr, action){
    switch (action.type) {
      case "language":{
        state={...state,language:action.payload}
        break;
        }
      case "langObj":{
        state={...state,langObj:action.payload}
        break;
        }      
    default:
        return state
      }
    return state;
  }
  export default mainreducer;